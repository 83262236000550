<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm
          ref="ajustesOptimizadorRef"
          :fields="this.ajustes_optimizador.fields"
          :card="this.ajustes_optimizador.card"
        >
  
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <BrunaForm
          ref="ajustesCapacidadesRef"
          :fields="this.ajustes_capacidades.fields"
          :card="this.ajustes_capacidades.card"
        >

        </BrunaForm>
      </b-col>
    </b-row>


    <b-row>
      <b-col>
        <BrunaForm
          ref="ajustesRef"
          :fields="this.ajustes_mezcla_objetivo.fields"
          :card="this.ajustes_mezcla_objetivo.card"
        >

        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <BrunaForm
          ref="ajustesAdministracionPilasRef"
          :fields="this.ajustes_administracion_pilas.fields"
          :card="this.ajustes_administracion_pilas.card"
        >

        </BrunaForm>
      </b-col>
    </b-row>



    

    <b-row>
        <b-col>
            <b-button size="md" @click="saveAjustes" variant="primary"
              >Guardar Ajustes</b-button
            >
        </b-col>
    </b-row>


  </section>
</template>
      
      <script>


import VueSlider from 'vue-slider-component'
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";


import useApiServices from '@/services/useApiServices.js';



import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {

    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,

    VueSlider,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,


    BOverlay,
    BSpinner,

    BrunaForm,



  },

  methods: {


    saveAjustes()
    {

      this.$refs.ajustesRef.validate().then((value) =>
      {

        if (value)
        {


           let data = {
                ajustes_optimizador: this.ajustes_optimizador.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
                ajustes_capacidades: this.ajustes_capacidades.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
                ajustes_mezcla_objetivo: this.ajustes_mezcla_objetivo.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
                ajustes_administracion_pilas: this.ajustes_administracion_pilas.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
            };

            

            console.log("save", data);

        }
      })



    }






  },




  mounted()
  {

    let self = this;

    //Se escucha a la llegada de las notificaciones de pusher






  },




  created()
  {




  },


  data()
  {

    let debug = (this.$route.query.debug == '1')


    return {


      showOverlay: false,

      ajustes_optimizador: {
        card: {
          title: "Optimizador",
          subTitle: "Selecciona el origen de datos que utilizaras para la planificación",
          tooltip: "",
          sidebarContent: {
            title: "Optimizador",
            body: ' '
          }
        },
        fields: [

          {
            label: "N° Máximo de Generaciones",
            type: "select",
            id: 'numero_max_generaciones',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 100, text: "100 generaciones" },
              { value: 200, text: "200 generaciones" },
              { value: 300, text: "300 generaciones" },
              { value: 400, text: "400 generaciones" },
              { value: 500, text: "500 generaciones" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 300
          },



          {
            label: "Tipo Función Objetivo",
            type: "select",
            id: 'tipo_funcion_objetivo',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 'default', text: "Maximizar FeMag y %Mag Mezcla" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 'default'
          },
         ]
      },


      ajustes_capacidades: {
        card: {
          title: "Capacidades",
          subTitle: "Selecciona el origen de datos que utilizaras para la planificación",
          tooltip: "",
          sidebarContent: {
            title: "Capacidades",
            body: ' '
          }
        },
        fields: [

        {
            label: "CV12 Flujo Mínimo (Ton/Hr)",
            type: "input",
            id: 'cv12_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },


          {
            label: "CV12 Flujo Máximo (Ton/Hr)",
            type: "input",
            id: 'cv12_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },


          {
            label: "CV14 Flujo Mínimo (Ton/Hr)",
            type: "input",
            id: 'cv14_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },


          {
            label: "CV14 Flujo Máximo (Ton/Hr)",
            type: "input",
            id: 'cv14_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },


          {
            label: "Flujo Total Mínimo Critico (Ton/Hr)",
            type: "input",
            id: 'total_flujo_min_critico',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 700
          },

          {
            label: "Flujo Total Mínimo (Ton/Hr)",
            type: "input",
            id: 'total_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 950
          },

          {
            label: "Flujo Total Máximo (Ton/Hr)",
            type: "input",
            id: 'total_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },


         
         ]
      },



      ajustes_mezcla_objetivo: {
        card: {
          title: "Mezcla Objetivo",
          subTitle: "Selecciona el origen de datos que utilizaras para la planificación",
          tooltip: "",
          sidebarContent: {
            title: "Ajustes Mezcla Objetivo",
            body: ' '
          }
        },
        fields: [

          {
            label: "FeMag Mezcla Mínimo",
            type: "input",
            id: 'femag_ponderado_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 31
          },

          {
            label: "FeMag Mezcla Máximo",
            type: "input",
            id: 'femag_ponderado_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 33
          },


          {
            label: "Magnetismo Mezcla Mínimo",
            type: "input",
            id: 'magnetismo_ponderado_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 86
          },

          {
            label: "Magnetismo Mezcla Máximo",
            type: "input",
            id: 'magnetismo_ponderado_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 89
          },




        ]
      },


      ajustes_administracion_pilas: {
        card: {
          title: "Administración de Pilas",
          subTitle: "Selecciona el origen de datos que utilizaras para la planificación",
          tooltip: "",
          sidebarContent: {
            title: "Administración de Pilas",
            body: ' '
          }
        },
        fields: [

          {
            label: "CV14 Pila 1 Status",
            type: "select",
            id: 'cv14_pila_1_status',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 'activado', text: "Activado" },
              { value: 'desactivado', text: "Desactivado" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 'activado'
          },

          {
            label: "CV14 Pila 1 Mínimo",
            type: "input",
            id: 'cv14_pila_1_minimo',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },

          {
            label: "CV14 Pila 1 Máximo",
            type: "input",
            id: 'cv14_pila_1_maximo',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 999999
          },


          {
            label: "CV14 Pila 2 Status",
            type: "select",
            id: 'cv14_pila_2_status',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 'activado', text: "Activado" },
              { value: 'desactivado', text: "Desactivado" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 'activado'
          },

          {
            label: "CV14 Pila 2 Mínimo",
            type: "input",
            id: 'cv14_pila_2_minimo',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },

          {
            label: "CV14 Pila 2 Máximo",
            type: "input",
            id: 'cv14_pila_2_maximo',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 999999
          },


          {
            label: "CV14 Pila 3 Status",
            type: "select",
            id: 'cv14_pila_3_status',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 'activado', text: "Activado" },
              { value: 'desactivado', text: "Desactivado" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 'desactivado'
          },

          {
            label: "CV14 Pila 3 Mínimo",
            type: "input",
            id: 'cv14_pila_3_minimo',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },

          {
            label: "CV14 Pila 3 Máximo",
            type: "input",
            id: 'cv14_pila_3_maximo',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 999999
          },







        ]
      },




    };
  },
};
      </script>
      <style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
    
    
    <style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
    
    <style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
      